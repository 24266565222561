import { Injectable } from '@angular/core';
import { ApiActions } from '../common/util.service';
import { Observable, Subject } from 'rxjs';
import { ServiceActions,  deleteUserDetails, defaultdeleteUserDetails } from 'src/app/model';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../common/app-config.service';
import { CommonDataService } from '../common/common-data.service';
import { MsgService } from 'src/app/service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeleteserviceService implements ServiceActions{

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private common: CommonDataService,
		private msgSvc: MsgService,
		private router: Router
  ) { }
  deleteData(id: number) {
    throw new Error('Method not implemented.');
  }
 
  updateData(object: {}) {
    throw new Error('Method not implemented.');
  }
  
  getDataRecords() {
    throw new Error('Method not implemented.');
  }

  private getUrl(apiOption: ApiActions) {
    return this.config.ipAddress + this.config.deleteuserApi[apiOption];
  }
  
  public pageName = 'deleteuseraccount';
  public dataList = new Subject<Array<deleteUserDetails>>();

  public addData(objData: deleteUserDetails): Observable<any> {
    const fData = this.fillData(objData); 
    return this.common.deleteexecuteAction(fData, this.getUrl(ApiActions.Delete), this.pageName).pipe(
      catchError((error) => {
        console.error('Error in addData:', error);
        throw error;
      })
    );
  }
  private fillData(objData: deleteUserDetails) {
    const fData = new FormData();
    fData.append('fname', objData.fname + '');
    fData.append('lname', objData.lname + '');
    fData.append('email', objData.email + '');
    fData.append('phone', objData.phone + '');
    fData.append('city', objData.city + '');

    return fData;
  }
}
